<template>
    <div class="fill-height">
        <v-container v-if="mdAndDown" class="fill-height text-center">
            <div
                class="d-flex flex-column justify-space-around fill-height fill-width"
            >
                <div class="d-flex flex-column fill-width">
                    <div class="max-w-100 mx-auto" style="height: 190.91px">
                        <div
                            v-show="isLogoAnimationCompleted"
                            style="
                                width: 344px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <img
                                style="max-width: 268px"
                                src="@/assets/images/logo/noodzly-logo.png"
                            />
                        </div>
                        <img
                            v-show="!isLogoAnimationCompleted"
                            style="width: 344px"
                            src="@/assets/logo_animation.gif"
                        />
                    </div>
                    <span class="splash-text mt-6">{{ welcomeMessage }}</span>
                </div>
                <div class="fill-width mt-8">
                    <btn-large
                        class="btn-accent"
                        to="/sign-up"
                        text="Sign Up"
                    />
                    <btn-large
                        class="grey darken-3 mt-3"
                        to="/login"
                        text="Login"
                    />
                </div>
                <div class="mt-8">
                    <v-divider />
                    <static-page-links template="sleeve" @openContactSupportPopup="showPopup = true" />
                    <div class="text-center mt-6">
                        <social-links class="justify-center" />
                    </div>
                </div>
                <footer-logo />
                <div class="mt-6">
                    <p class="copyright-text" v-html="$t('pages.copyright', { year: new Date().getFullYear() })"></p>
                </div>
            </div>
        </v-container>

        <div v-else class="fill-height d-flex flex-column">
            <div class="mt-10 max-w-100 mx-auto" style="height: 190.91px">
                <div
                    v-show="isLogoAnimationCompleted"
                    style="
                        width: 344px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    "
                >
                    <img
                        style="max-width: 268px"
                        src="@/assets/images/logo/noodzly-logo.png"
                    />
                </div>
                <img
                    v-show="!isLogoAnimationCompleted"
                    style="width: 344px"
                    src="@/assets/logo_animation.gif"
                />
            </div>
            <v-container class="container-splash px-10">
                <v-row justify="space-between" align="center">
                    <v-col cols="5">
                        <div
                            ref="parallaxScene"
                            class="animate__animated animate__fadeInUp"
                        >
                            <div
                                data-depth="0.2"
                                class="parallax-scene__screen home-screen"
                            >
                                <v-card
                                    rounded="xl"
                                    class="mt-12"
                                    :max-width="screenWidth"
                                >
                                    <v-img
                                        :width="screenWidth"
                                        src="@/assets/images/splash/app-home-screen-2.jpg"
                                    />
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6" class="text-center">
                        <div
                            class="animate__animated animate__fadeIn animate__slow"
                        >
                            <div class="splash-text">
                                {{ $t("pages.home.desktop_description") }}
                            </div>
                            <div class="qrcode">
                                <div class="qrcode-text">
                                    {{ $t("pages.home.qr_code_sub_text") }}
                                </div>
                                <img
                                    :src="
                                        require('@/assets/images/home/noodzly-qrcode.jpg')
                                    "
                                    alt="qrcode"
                                    title="qrcode"
                                    class="qrcode-img"
                                />
                                <div class="qrcode-text">
                                    {{ $t("pages.home.qr_code_text") }}
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <div
                class="mt-auto animate__animated animate__fadeInUp animate__fast"
            >
                <v-divider />
                <div class="px-10 pt-10">
                    <v-row>
                        <v-col cols="4">
                            <div>
                                <img
                                    style="max-width: 268px"
                                    src="@/assets/images/logo/noodzly-logo.png"
                                />
                                <social-links />
                            </div>
                        </v-col>

                        <v-col cols="8">                            
                            <div>
                                <static-page-links template="simple" @openContactSupportPopup="showPopup = true" />
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="position-relative copyright-footer" style="justify-items: center;">
                            <p class="" v-html="$t('pages.copyright', { year: new Date().getFullYear() })"></p>
                            <footer-logo class="mt-0" />
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>

        <ContactUsDialog v-if="showPopup" @close="closePopup" />
    </div>
</template>

<script>
import breaks from "@/mixins/breaks";
import Parallax from "parallax-js";
import SocialLinks from "@/components/app/auth/SocialLinks";
import { MESSAGE_ON_WELCOME_PAGE } from "@/configs/constants";
import StaticPageLinks from "@/components/app/common/StaticPageLinks";
import FooterContact from "@/components/app/common/FooterContact";
import FooterLogo from "@/components/app/common/FooterLogo";
import ContactUsDialog from "@/components/app/dialogs/ContactUsDialog";

export default {
    name: "SplashScreen",
    metaInfo() {
        return {
            meta: [
                {
                    name: 'title',
                    content: 'Best Adult Social Network & Secure Content Sharing for Creators',
                    vmid: 'title'
                },
                {
                    name: 'description',
                    content: 'Join the best adult social network for content creators. Share exclusive and secure amateur content. Monetize your work and connect on a privacy-focused platform.',
                    vmid: 'description'
                }
            ],
        };
    },
    mixins: [breaks],
    data: () => ({
        parallaxSceneEl: null,
        parallaxInstance: null,
        welcomeMessage: MESSAGE_ON_WELCOME_PAGE,
        isLogoAnimationCompleted: false,
        showPopup: false,
        originalMetaDescription: null,
        scriptTag: null,
    }),
    async mounted() {
        if (this.$route.query.showPopup === 'true') {
            this.showPopup = true;
        }

        await this.navigateToHomePage();

        if (this.lgAndUp) {
            this.parallaxSceneInit();
        }

        setTimeout(() => {
            this.isLogoAnimationCompleted = true;
        }, 3000);

        const existingMeta = document.querySelector('meta[name="description"]');
        if (existingMeta) {
            this.originalMetaDescription = existingMeta.getAttribute('content');
            existingMeta.remove();
        }       

        this.scriptTag = document.createElement("script");
        this.scriptTag.type = "application/ld+json";
        this.scriptTag.textContent = JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "noodzly",
            "url": "https://noodzly.com/",
            "potentialAction": {
                "@type": "SearchAction",
                "target": "{search_term_string}",
                "query-input": "required name=search_term_string"
            }
        });

        document.head.appendChild(this.scriptTag);
    },
    computed: {
        screenWidth() {
            if (this.xlOnly) {
                return 289;
            }
            if (this.lgAndUp) {
                return 230;
            }

            return 150;
        },
    },
    beforeDestroy() {
        if (this.parallaxInstance) this.parallaxInstance.destroy();
        this.hideTawkWidget();
        if (this.scriptTag && document.head.contains(this.scriptTag)) {
            document.head.removeChild(this.scriptTag);
        }
        if (this.originalMetaDescription) {
            const metaTag = document.createElement('meta');
            metaTag.name = 'description';
            metaTag.content = this.originalMetaDescription;
            document.head.appendChild(metaTag);
        }
    },
    methods: {
        navigateToHomePage: _.debounce(async function () {
            if (this.$auth.user() && this.showPopup == false) {
                await this.hideTawkWidget();
                this.$router
                    .push({ name: "auth.home" })
                    .catch((e) => console.log(e));
            } else {
                this.loadTawkWidget();
            }
        }, 200),
        closePopup(){
            this.showPopup = false;
            this.navigateToHomePage();
        },
        parallaxSceneInit() {
            this.parallaxSceneEl = this.$refs.parallaxScene;
            this.parallaxInstance = new Parallax(this.parallaxSceneEl, {
                pointerEvents: true,
            });
        },
        loadTawkWidget(){
            const scriptSrc = `https://embed.tawk.to/${process.env.VUE_APP_TAWK_PROPERTY_ID}/${process.env.VUE_APP_TAWK_WIDGET_ID}`;
            if (!window.Tawk_API) {
                // Dynamically load the Tawk.to script if not already loaded
                const tawkScript = document.createElement("script");
                tawkScript.id = "tawk-script";
                tawkScript.src = scriptSrc;
                tawkScript.async = true;
                tawkScript.charset = "UTF-8";
                tawkScript.setAttribute("crossorigin", "*");
                document.head.appendChild(tawkScript);

                window.Tawk_API = window.Tawk_API || {};
                window.Tawk_LoadStart = new Date();

                // Wait for the script to load, then show the widget
                tawkScript.onload = () => {
                    if (window.Tawk_API.showWidget) {
                        window.Tawk_API.showWidget();
                    }
                };
            } else {
                // If the widget is already loaded, show it
                if (window.Tawk_API.showWidget) {
                    window.Tawk_API.showWidget();
                }
            }
        },
        hideTawkWidget() {
            if (window.Tawk_API && window.Tawk_API.hideWidget) {
                window.Tawk_API.hideWidget();
            }
        },
    },
    components: {
        FooterLogo,
        FooterContact,
        SocialLinks,
        StaticPageLinks,
        ContactUsDialog,
    },
};
</script>

<style lang="scss">
@import "../../sass/vuetify/variables";

.qrcode {
    margin-top: 55px;
}
.qrcode-text {
    font-size: 1.8rem;
}
.qrcode-img {
    max-width: 180px;
    margin: 15px 0;
}
.splash-text {
    font-size: 4.6vw;
}

.parallax-scene {
    &__screen {
        &.splash-screen {
            margin-left: 280px;
        }

        &.home-screen {
            margin-left: 100px;
        }

        .v-card {
            box-shadow: -40px 30px 70px rgba(#000, 0.36) !important;
        }
    }
}

@include media("md-and-up") {
    .splash-text {
        font-size: 1.8rem;
    }
}

@include media("xl-only") {
    .splash-text {
        font-size: 2rem;
    }
    .parallax-scene {
        &__screen {
            &.splash-screen {
                margin-left: 230px;
            }

            &.home-screen {
                margin-left: 0;
            }

            .v-card {
                box-shadow: -60px 50px 96px rgba(#000, 0.36) !important;
            }
        }
    }
}

.copyright-footer {
    p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-bottom: 0%;
    }
   
}

.copyright-text {
    font-size: 0.8rem;
}
</style>
